import THREE, {
  DomElement,
  DomEventCallback,
  WebGLJson,
  WebGLOrbitControls,
  WebGLObject3D,
  WebGLRenderer,
  WebGLScene,
  WebGLCamera,
  PartObjs,
  WebGLAnimationLoader,
  Frame
} from 'THREE';
import TWEEN from 'TWEEN';
import { ThumbnailRenderers } from '../constants/thumbnail3dConstant';
import thumbnailUntil from './thumbnail3dUtil';

type PartsMap = { [key: string]: WebGLObject3D };
type LoadedPart = [partName: string, part: WebGLObject3D];
type OnAnimationLoopFinish = (numOfLoops: number) => void;
const { addLightsToScene } = thumbnailUntil;

const minRenderDistanceDefault = 0.1;
const maxRenderDistanceDefault = 1000;
const renderers: ThumbnailRenderers = {};

// Some weird dependency system problems need to be bypassed
const THREE = (window.THREE as unknown) as THREE;

const getRenderer = (key: string, container: DomElement) => {
  if (!renderers[key]) {
    renderers[key] = {
      container,
      renderer: new THREE.WebGLRenderer({ antialias: true, alpha: true })
    };
  }
  return renderers[key].renderer;
};

const containerWidth = (container: DomElement) => {
  return container.parentElement?.offsetWidth;
};

const containerHeight = (container: DomElement) => {
  return container.parentElement?.offsetHeight;
};

const render = (renderer: WebGLRenderer, scene: WebGLScene, camera: WebGLCamera) => {
  renderer.render(scene, camera);
};

const initializeControls = (
  scene: WebGLScene,
  camera: WebGLCamera,
  container: DomElement,
  json: WebGLJson,
  onChange: DomEventCallback
) => {
  // The controller that lets us spin the camera around an object
  const animatedOrbitControls = new THREE.OrbitControls(camera, container, json, 'animated');
  animatedOrbitControls.addEventListener('change', onChange);
  return animatedOrbitControls;
};

const createCanvas = (renderer: WebGLRenderer, container: DomElement, camera: WebGLCamera) => {
  const setRendererSize = () => {
    camera.aspect = containerWidth(container) / containerHeight(container);
    camera.updateProjectionMatrix();
    renderer.setSize(containerWidth(container), containerHeight(container));
  };

  renderer.setSize(containerWidth(container), containerHeight(container));
  const canvas = renderer.domElement;
  let resizeTimer = 0;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(setRendererSize, 100);
  });
  window.addEventListener('beforeunload', () => {
    // canvas goes black when navigating to another page
    canvas.style.display = 'none';
  });
  return canvas;
};

const loadObjMtlPart = (
  objMtlLoader: WebGLAnimationLoader,
  partName: string,
  partobjHash: string,
  partmtlData: string
) => {
  return new Promise((resolve, reject) => {
    objMtlLoader.load(partobjHash, partmtlData, (obj, mtl) => {
      mtl.preload();
      const mtlLoaderCreator = new THREE.MaterialCreator();
      mtlLoaderCreator.setMaterials(mtl);

      // Bounding box hacks because three.js rotations are annoying >:(
      obj.children[0].geometry.computeBoundingBox();
      const { boundingBox } = obj.children[0].geometry;
      const position = new THREE.Vector3();
      position.subVectors(boundingBox.max, boundingBox.min);
      position.multiplyScalar(0.5);
      position.add(boundingBox.min);
      position.applyMatrix4(obj.children[0].matrixWorld);

      // Ensure mesh rotates about its center
      obj.children[0].geometry.applyMatrix4(
        new THREE.Matrix4().makeTranslation(-position.x, -position.y, -position.z)
      );

      obj.children[0].geometry.verticesNeedUpdate = true;
      obj.children[0].position.x = position.x;
      obj.children[0].position.y = position.y;
      obj.children[0].position.z = position.z;
      obj.matrixAutoUpdate = false;

      resolve([partName, obj]);
    });
  });
};
const loadPartsMap = (objMtlLoader: WebGLAnimationLoader, partObjs: PartObjs, partNames: string[]) => {
  return Promise.all(
    partNames.map(partName => {
      const part = partObjs[partName];
      const partObjHash = part.files['scene.obj'].content;
      const partMtlData = part.files['scene.mtl'].content;
      return loadObjMtlPart(objMtlLoader, partName, partObjHash, partMtlData);
    })
  );
};

// Animate a single frame
const animateFrame = (
  renderer: WebGLRenderer,
  controls: WebGLOrbitControls,
  camera: WebGLCamera,
  scene: WebGLScene,
  curFrame: number,
  frames: Frame[],
  partsMap: PartsMap,
  partNames: string[]
) => {
  controls.update();
  const animJson = frames[curFrame];
  for (let ii = 0; ii < partNames.length; ++ii) {
    const partPosition = animJson[partNames[ii]];
    const partObject = partsMap[partNames[ii]];
    if (typeof partPosition !== 'undefined' && typeof partObject !== 'undefined') {
      partObject.children[0].position.set(
        partPosition.Position.x,
        partPosition.Position.y,
        partPosition.Position.z
      );
      partObject.children[0].quaternion.set(
        partPosition.Rotation.x,
        partPosition.Rotation.y,
        partPosition.Rotation.z,
        partPosition.Rotation.w
      );
      partObject.updateMatrix();
    }
  }
  scene.updateMatrixWorld(true);

  if (controls.enabled) {
    controls.update();
  }
  TWEEN.update();
  render(renderer, scene, camera);
};

const loadObjAndMtlAnimatedAsset = (
  targetId: number,
  container: DomElement,
  json: WebGLJson,
  onAnimationLoopFinish: OnAnimationLoopFinish
) => {
  return new Promise((resolve, reject) => {
    const rendererKey = `THREE_renderer_targetId_${targetId}`;
    const renderer = getRenderer(rendererKey, container);
    const { max } = json.aabb;
    const calculatedMaxRenderDistance = new THREE.Vector3(max.x, max.y, max.z).length() * 4;
    const maxRenderDistance = Math.max(calculatedMaxRenderDistance, maxRenderDistanceDefault);
    const fieldOfView = typeof json.camera.fov !== 'undefined' ? json.camera.fov : 70;
    const camera = new THREE.PerspectiveCamera(
      fieldOfView,
      containerWidth(container) / containerHeight(container),
      minRenderDistanceDefault,
      maxRenderDistance
    );

    const scene = new THREE.Scene();
    scene.add(camera);

    let controls: WebGLOrbitControls;
    const partsMap: PartsMap = {};
    const { frames } = json;
    let curFrame = 0;
    let numOfLoops = 0;
    let animationLoader = new THREE.AnimationLoader();

    // Load the obj for each part in the character model
    const partNames = Object.keys(json.partobjs);

    return loadPartsMap(animationLoader, json.partobjs, partNames)
      .then((loadedParts: LoadedPart[]) => {
        loadedParts.forEach(([partName, part]) => {
          // populate partMaps
          partsMap[partName] = part;
          // add part to the scene
          part.matrixAutoUpdate = false;
          scene.add(part);
        });

        addLightsToScene(scene, camera, false);
        scene.add(camera);

        const canvas = createCanvas(renderer, container, camera);
        controls = initializeControls(scene, camera, container, json, () => {
          render(renderer, scene, camera);
        });

        render(renderer, scene, camera);
        let animationRequestId = 0;
        function animate() {
           if (curFrame === frames.length) {
            // reset frame after each loop
            curFrame = 0;
            numOfLoops += 1;
            if (onAnimationLoopFinish) {
              onAnimationLoopFinish(numOfLoops);
            }
          }         
          // animate a single frame
          animateFrame(renderer, controls, camera, scene, curFrame, frames, partsMap, partNames);
          curFrame++;
          animationRequestId = requestAnimationFrame(animate);
        }

        function stopAnimationFrame()
        {
          cancelAnimationFrame(animationRequestId)
        }

        animate();
        resolve({ canvas, stopAnimationFrame});

      })
      .catch(error => {
        console.error('Error loading parts:', error);
      });
  });
};

export default {
  loadObjAndMtlAnimatedAsset
};
